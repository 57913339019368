import { render, staticRenderFns } from "./Request.Rejected.component.vue?vue&type=template&id=a8ca4e64&scoped=true&"
import script from "./Request.Rejected.component.vue?vue&type=script&lang=ts&"
export * from "./Request.Rejected.component.vue?vue&type=script&lang=ts&"
import style0 from "./Request.Rejected.component.vue?vue&type=style&index=0&id=a8ca4e64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8ca4e64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VImg,VTextarea})
