











































import { Component, Prop } from "vue-property-decorator";
import { email, integer, maxLength, minLength, minValue, required } from "vuelidate/lib/validators";
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import User from "@/models/User.model";
import {Gender} from "@/enum/UserTitle.enum";
import {UserDegree} from "@/enum/UserDegree";
import {AUTH_STORE_NAME, AuthStoreActions, AuthStoreGetters} from "@/store/auth.store";
import {namespace} from "vuex-class";
import IconTooltipComponent from "@/components/IconTooltip.component.vue";
import {UserType} from "@/enum/UserType.enum";
import Address from '@/models/Address';
import BillingDetails from "@/models/BillingDetails";
import { ApplicationStatus } from "@/enum/ApplicationStatus.enum";
import Application from "@/models/Application";
import { APPLICATION_STORE_NAME, ApplicationStoreActions } from "@/store/application.store";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  mixins: [validationMixin],
  validations: {
    description: { required, minLength: minLength(5), maxLength: maxLength(500)},
  }
})
export default class RequestRejectedComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({ default: Application.parseFromObject({}) })
  public application!: Application;

  @ApplicationStore.Action(ApplicationStoreActions.REQUEST_APPLICATION)
  private acceptApplication!: (payload: { applicationId: string ,status: ApplicationStatus, description: string}) => Promise< any | null>;

  @ApplicationStore.Action(ApplicationStoreActions.GET_APPLICATIONS)
  private getApplication!: (payload: {
    status?: ApplicationStatus,
    overwrite?: boolean
  }) => Promise<any | null>;

  private description: string = "";


  private async RejectedRequest() {
    // Trigger validation
    this.$v.$touch();

    // Form is Invalid return here
    if (this.$v.$invalid) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
      return;
    }

    try {
      if(this.application === undefined || this.application.id === undefined) return;
      this.isLoading = true;
      await this.acceptApplication({
        applicationId: this.application.id!,
        status: ApplicationStatus.Rejected,
        description: this.description
      });

      await this.getApplication({ overwrite: true });

      this.$notifySuccessSimplified("GENERAL.NOTIFICATIONS.REJECTED_APPLICATION");
      this.dismiss();
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 409:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.FAILED_TO_UPDATE_APPLICATION");
            break;
          case 423:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.APPLICATION_STATUS");
            break;
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.ERROR_GENERAL");
            break;
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  private dismiss(reload: boolean = false) {
    this.$emit('closeDialog');
  }

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;
}
